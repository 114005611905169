import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import Search from "../pages/search"
import Link from '../utils/link'
import Logo from '../assets/images/logo.svg'
import LogoSmall from '../assets/images/sh-logo.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    offCanvasGallery: false,
    offCanvasDesigner: false,
    dropdown: false,
    scrolled: false,
    submenus: {
      gallery: false,
      designers: false,
      // Add more submenu states here
    },
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 1
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _toggleSubmenu = (submenuName) => {
    const { submenus } = this.state;

    const updatedSubmenus = Object.keys(submenus).reduce((acc, key) => {
      acc[key] = key === submenuName ? !submenus[key] : false;
      return acc;
    }, {});

    this.setState({
      offCanvas: true, // Close main menu
      submenus: updatedSubmenus,
    });
  }

  _toggleMobileGallery = () => {
    this.setState((prevState) => ({
      submenus: {
        ...prevState.submenus,
        gallery: !prevState.submenus.gallery,
        designers: false, // Close the Gallery submenu
      },
    }));
  };

  _toggleMobileDesigners = () => {
    this.setState((prevState) => ({
      submenus: {
        ...prevState.submenus,
        designers: !prevState.submenus.designers,
        gallery: false, // Close the Gallery submenu
      },
    }));
  }

  _toggleOffCanvas = () => {
    this.setState((prevState) => ({
      offCanvas: !prevState.offCanvas,
      offCanvasGallery: false, // Close Gallery submenu
      offCanvasDesigner: false, // Close Designer submenu
    }));
  }

  _toggleGallery = () => {
    this.setState((prevState) => ({
      offCanvasGallery: !prevState.offCanvasGallery,
      offCanvas: false, // Close Menu submenu
      offCanvasDesigner: false, // Close Designer submenu
    }));
  }

  _toggleDesigner = () => {
    const designerNameElement = document.querySelector('.collections__categorie.designer-name');
  
    if (designerNameElement) {
      designerNameElement.style.display = this.state.offCanvasDesigner ? 'block' : 'none';
    }

    this.setState((prevState) => ({
      offCanvasDesigner: !prevState.offCanvasDesigner,
      offCanvas: false, // Close Menu submenu
      offCanvasGallery: false, // Close Gallery submenu
    }));
  }

  render() {
    let { offCanvas, offCanvasGallery, offCanvasDesigner, dropdown, scrolled } = this.state

    let props = {
      activeClassName: 'active',
      onClick: (event) => {
        this.setState({ offCanvas: false })
        this.setState({ offCanvasGallery: false })
        this.setState({ offCanvasDesigner: false })
      },
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' header--open'
    if (offCanvasGallery) headerClass += ' header--gallery-open'
    if (offCanvasDesigner) headerClass += ' header--designer-open'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>

            <div className='header__col'>
              <div className='header__logo'>
                <Link to='/' {...props}>
                  <img className='large' src={Logo} alt='Seventh House' />
                  <img className='small' src={LogoSmall} alt='Seventh House' />
                </Link>
              </div>
            </div>

            <div className='header__col'>

            </div>

            <div className='header__col menu-container'>
              <div className='header__col--inner'>
                <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__menu active' : 'header__menu' }>
                  MENU
                </button>

                <ul className='header__navigation nav-desktop' {...props}>
                  <li><Link to='/products/new-arrivals'>New Arrivals</Link></li>
                  <li>
                    <Link to='/products/table'>Table</Link>
                    <ul class="submenu">
                      <li><Link to='/products/coffee'>Coffee</Link></li>
                      <li><Link to='/products/dining'>Dining</Link></li>
                      <li><Link to='/products/outdoor-tables'>Outdoor</Link></li>
                      <li><Link to='/products/pedestal'>Pedestal</Link></li>
                      <li><Link to='/products/side'>Side</Link></li>
                    </ul>
                  </li>
                  <li><Link to='/products/desk'>Desk</Link></li>
                  <li><Link to='/products/console'>Console</Link></li>
                  <li><Link to='/products/storage'>Storage</Link></li>
                  <li><Link to='/products/daybed'>Daybed</Link></li>
                  <li>
                    <Link to='/products/seating'>Seating</Link>
                    <ul class="submenu">
                      <li><Link to='/products/lounge-chair'>Lounge Chair</Link></li>
                      <li><Link to='/products/dining-chair'>Dining Chair</Link></li>
                      <li><Link to='/products/side-chair'>Side Chair</Link></li>
                      <li><Link to='/products/sofa'>sofa</Link></li>
                      <li><Link to='/products/bench'>Bench</Link></li>
                      <li><Link to='/products/stool'>stool</Link></li>
                      <li><Link to='/products/outdoor-seating'>Outdoor</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/products/lighting'>Lighting</Link>
                    <ul class="submenu">
                      <li><Link to='/products/Pendant'>PENDANT</Link></li>
                      <li><Link to='/products/Sconce'>Sconce</Link></li>
                      <li><Link to='/products/floor-lamp'>Floor Lamp</Link></li>
                      <li><Link to='/products/table-lamp'>Table Lamp</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/products/object'>Object</Link>
                    <ul class="submenu">
                      <li><Link to='/products/mirror'>Mirror</Link></li>
                      <li><Link to='/products/tableware'>Tableware</Link></li>
                      <li><Link to='/products/carpet'>Carpet</Link></li>
                      <li><Link to='/products/accessories'>Accessories</Link></li>
                      <li><Link to='/products/garden'>Garden</Link></li>
                    </ul>
                  </li>
                  <li>
                    <Link to='/products/outdoor'>Outdoor</Link>
                  </li>
                </ul>

                <ul className='header__navigation nav-mobile'>
                <li><Link to='/#' {...props}>Close</Link></li>
                  <li><Link to='/products/new-arrivals' {...props}>New Arrivals</Link></li>
                  <li><Link to='/products/table' {...props}>Table</Link></li>
                  <li><Link to='/products/desk' {...props}>Desk</Link></li>
                  <li><Link to='/products/console' {...props}>Console</Link></li>
                  <li><Link to='/products/storage' {...props}>Storage</Link></li>
                  <li><Link to='/products/daybed' {...props}>Daybed</Link></li>
                  <li><Link to='/products/seating' {...props}>Seating</Link></li>
                  <li><Link to='/products/lighting' {...props}>Lighting</Link></li>
                  <li><Link to='/products/object' {...props}>Object</Link></li>
                  <li><Link to='/products/outdoor' {...props}>Outdoor</Link></li>

                  <li>
                  <Link to='#' onClick={this._toggleMobileGallery}>Gallery</Link>
                    <ul className={this.state.submenus.gallery ? 'submenu open' : 'submenu'}>
                      <li><Link to='/about' {...props}>About</Link></li>
                      <li><Link to='/exhibition' {...props}>Exhibitions</Link></li>
                      {/* <li><Link to='/courtney-applebaum' {...props}>Exhibitions</Link></li> */}
                      <li><Link to='/architecture' {...props}>Architecture</Link></li>
                      <li><Link to='/contact' {...props}>Contact</Link></li>
                      {/* <li><Link to='#contact' {...props}>Contact</Link></li> */}
                    </ul>
                  </li>

                  <li>
                  <Link to='#' onClick={this._toggleMobileDesigners}>Designers</Link>
                    <ul className={this.state.submenus.designers ? 'submenu open' : 'submenu'}>
                      <li><Link to='/designers/contemporary' {...props}>Contemporary Designers</Link></li>
                      <li><Link to='/designers/vintage' {...props}>Vintage Designers</Link></li>
                    </ul>
                  </li>
                  
                  <li>
                    <form class="header__search" action="/search" _lpchecked="1">
                      <input type="text" name="search" placeholder="SEARCH"/>
                      <button type="submit" {...props}>&#8594;</button>
                    </form>
                  </li>
                </ul>
              </div>

            </div>


            <div className='header__col'>
              <button onClick={this._toggleGallery} className={ offCanvasGallery ? 'header__menu-gallery active' : 'header__menu-gallery' }>
                GALLERY
              </button>
              <ul className='header__navigation-gallery nav-desktop' {...props}>
                <li>
                  <Link className='header__desktop-link' to='/about' {...props}>About</Link>
                </li>

                  <li>
                    {/* <Link to='/exhibition' {...props}>Exhibitions</Link> */}
                    <Link to='/courtney-applebaum' {...props}>Exhibitions</Link>
                    <ul className="submenu">
                      <li><Link to='/exhibition' {...props}>Current - Luck Carpentry</Link></li>
                      {/* <li><Link to='/exhibition' {...props}>Current</Link></li> */}
                      {/* <li><Link to='/past-exhibitions'>Past</Link></li> */}
                    </ul>

                    <ul className="submenu">
                      {/* <li className="submenu-past-parent"><Link to='/exhibition' {...props}>Past</Link> */}
                      <li className="submenu-past-parent"><Link to='/courtney-applebaum' {...props}>Past</Link>

                      <ul className="submenu-past">
                        <li><Link to='/courtney-applebaum' {...props}>Courtney Applebaum Design</Link></li>
                        <li><Link to='/studionouaunu' {...props}>Studio Noua Unu</Link></li>
                        <li><Link to='/studio-giancarlo-valle' {...props}>Studio Giancarlo Valle</Link></li>
                        <li><Link to='/william-wright-exhibition' {...props}>William Wright | Quiet Days</Link></li>
                        <li><Link to='/green-river-project-exhibition' {...props}>Green River Project | Twig Collection</Link></li>
                      </ul>

                      </li>
                    </ul>
                  </li>
                <li>
                  <Link className='header__desktop-link' to='/architecture' {...props}>Architecture</Link>
                </li>
                <li>
                  <Link className='header__desktop-link' to='/contact' {...props}>Contact</Link>
                </li>
              </ul>
            </div>


            <div className='header__col'>
              <button onClick={this._toggleDesigner} className={ offCanvasDesigner ? 'header__menu-designer active' : 'header__menu-designer' }>
                DESIGNERS
              </button>
              <ul className='header__navigation-designer nav-desktop' {...props}>
                <li>
                  <Link className='header__desktop-link' to='/designers/contemporary' {...props}>Contemporary</Link>
                </li>
                <li>
                  <Link className='header__desktop-link' to='/designers/vintage' {...props}>Vintage</Link>
                </li>
              </ul>
            </div>

            <div className='header__col'>
              {/* <Link to='#search' {...props}>Search</Link> */}

              <form class="header__search header__desktop-link" action="/search" _lpchecked="1">
                <input type="text" name="search" placeholder="SEARCH" {...props} />
                {/* <button type="submit"></button> */}
              </form>
            </div>

          </div>
        </header>

      </>
    )
  }
}

export default Header
